import request from "@/lib/request";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {IMark, IQueryParam, IRow} from "@/layouts/workspace/follow/free_shop_follow_up/typings";

export async function fetchPage(info: IQueryParam): Promise<IRow[]> {
    const rs = await request.request<unknown, AxiosResponse, unknown>(({
        url: "shop/free/followup",
        method: 'post',
        data: info
    } as AxiosRequestConfig));
    return rs.data;
}

export async function doUpdateMark(info: IMark): Promise<unknown> {
    const rs = await request.request<unknown, AxiosResponse, unknown>(({
        url: "shop/account/mark",
        method: 'put',
        data: info
    } as AxiosRequestConfig));
    return rs.data;
}
